var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"fixed-top d-flex align-items-center",attrs:{"id":"header"}},[_c('div',{staticClass:"container d-flex justify-content-between"},[_vm._m(0),_c('nav',{staticClass:"navbar",attrs:{"id":"navbar"}},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.isSignIn)?_c('button',{staticClass:"btn-style ml-15",on:{"click":_vm.logout}},[_vm._v(" LOGOUT ")]):_vm._e()]),_c('i',{staticClass:"bi bi-list mobile-nav-toggle"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../public/assets/img/logo/logo1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto active",attrs:{"href":"#about"}},[_vm._v("ABOUT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"#team"}},[_vm._v("TEAM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"https://www.linkedin.com/in/medha-krishnan-a3044457/?originalSubdomain=in","target":"_blank"}},[_vm._v("CAREERS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"nav-link scrollto",attrs:{"href":"#contact"}},[_vm._v("CONTACT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://tan-ninety.myshopify.com/","target":"_blank"}},[_c('button',{staticClass:"btn-style1"},[_vm._v("SHOP NOW")])])
}]

export { render, staticRenderFns }