<template>
  <div class="home">
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="clearfix">
      <div class="container" data-aos="fade-up">
        <div class="hero-info" data-aos="zoom-in" data-aos-delay="100">
          <h2>Helping students level up to their maximum potential</h2>
          <a href="#explore"
            ><button class="btn-style3"><b>EXPLORE</b></button></a
          >
        </div>
      </div>
    </section>
    <!-- End Hero Section -->
    <!-- ======= About Section ======= -->
    <section id="about">
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <p>
            In today’s hyper-connected world, democratizing access to
            educational resources that help learners think rather than cram
            continues to remain a challenge.
          </p>
        </header>
      </div>
    </section>
    <!-- End About Section -->
    <section class="mission-section">
      <div class="container">
        <div class="row justify-content-center about-extra">
          <div
            class="services-style-one col-lg-6 col-md-6 zoom align-items-stretch aos-init aos-animate"
          >
            <div class="inner-box vision-height">
              <div class="icon-box">
                <img src="/assets/img/eye.png" width="70" />
              </div>
              <h3 class="pt-3">VISION</h3>
              <p class="tj">
                Help high schoolers overcome their fear of Mathematics by
                providing them access to customized resources and building
                personalised learning journeys.
              </p>
            </div>
          </div>
          <div class="services-style-one zoom col-lg-6 col-md-6 mt-5rem">
            <div class="inner-box vision-height">
              <div class="icon-box">
                <img src="/assets/img/mission.png" width="50" />
              </div>
              <h3 class="pt-3">MISSION</h3>
              <p class="tj">
                Leverage technology to build an ecosystem of resources and
                services that help high schoolers become thinkers who rely on
                conceptual clarity rather than muscle memory for academic
                success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======= Product Section Starts======= -->
    <section id="explore" class="services section-bg section-product">
      <div class="container">
        <div>
          <div class="section-title1">
            <p class="text-center text-white">EXPLORE</p>
          </div>
          <div class="mt-5 row align-items-center">
            <div
              class="col-lg-6 col-md-6 align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="assets/img/box/Copy of P1010078.jpg"
                      width="100%"
                      height="100%"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/img/box/Copy of P1010086.jpg"
                      width="100%"
                      height="100%"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/img/box/Copy of P1010090.jpg"
                      width="100%"
                      height="100%"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/img/box/Copy of P1010094.jpg"
                      width="100%"
                      height="100%"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/img/box/Copy of Screenshot 2022-07-29 at 4.33.16 PM.jpg"
                      width="100%"
                      height="100%"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div
              class="col-lg-6 col-md-6 align-items-stretch mt-3rem"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h3 class="vision-title float-left text-white">
                NATKHAT LAB IN A BOX
              </h3>
              <h5 class="text-white"><i>Unbox your child’s creativity!</i></h5>
              <p class="text-vision-mission text-white">
                STEM learning doesn’t have to be boring & limited to the
                classroom – parents can support and aid STEM learning too. Our
                curriculum-aligned kits make tinkering easier and so much more
                engaging.<br /><br />
                Gift our fun boxes to your kids so they start thinking outside
                the box.
              </p>

              <a href="https://tan-ninety.myshopify.com/" target="_blank"
                ><button class="btn-style3"><b>Explore Now</b></button></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="home" class="services section-product">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-6 col-md-6 align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h3 class="vision-title">NATKHAT LAB AT HOME</h3>
            <h5 class="mt-4 ft-26">
              <i>Your very own personal tinkering studio!</i>
            </h5>
            <p class="mt-4 text-vision-mission text-justify ft-24">
              Set up a creative place for your kids to become budding
              innovators. Ideas, materials, challenges & steps to start - we
              have got them all covered. Join your kids on their adventures -
              You Create, We facilitate.
            </p>

            <a href="https://tan-ninety.myshopify.com/" target="_blank"
              ><button class="btn-style3"><b>Set Up Now</b></button></a
            >
          </div>
          <div
            class="col-lg-6 col-md-6 align-items-center mt-3rem"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <!-- <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button> -->
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/img/home/1.jpg"
                    width="100%"
                    height="100%"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/home/2.jpg"
                    width="100%"
                    height="100%"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/home/3.jpg"
                    width="100%"
                    height="100%"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/home/4.jpg"
                    width="100%"
                    height="100%"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="ergo" class="text-white services section-bg section-product">
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-lg-6 col-md-6 align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="assets/img/school/1.jpg"
                    width="100%"
                    height="100%"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/school/2.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/school/3.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="assets/img/school/4.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <!-- <div class="carousel-item">
                            <img src="assets/img/discover/science3.jpg" class="d-block w-100" alt="..." />
                            </div> -->
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 align-items-center"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h3 class="mt-5 vision-title">NATKHAT LAB AT SCHOOL</h3>
            <h5 class="mt-4">
              <i
                >Unleash your students creativity and bring their ideas to
                life!</i
              >
            </h5>
            <p class="text-vision-mission">
              Looking to set up a makerspace in your school or community and
              wondering how to go about it? We can help you identify the next
              Kalam and Tesla in your classrooms!
              <br /><br />
              Tan90 helps schools and institutions set-up curricula aligned
              makerspaces where students can play, tinker, create and learn on
              the go.
              <br /><br />
              We believe students learn best when they are able to visualize and
              experience concepts that are taught in their classrooms.
            </p>

            <a href="#contact"
              ><button class="btn-style3"><b>Talk To Us</b></button></a
            >
          </div>
        </div>
      </div>
    </section>
    <section id="online-tribe" class="services section-product">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-lg-6 col-md-6 align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <h3 class="vision-title">TAN90 Online Tribe</h3>
            <h5 class="mt-4 ft-26">
              <i>Tinker and Learn together!</i>
            </h5>
            <p class="mt-4 text-vision-mission text-justify ft-24">
              Bored of tinkering alone? Don’t know what to do next after your
              experiment is complete? Come join the Tan90 community!<br /><br />
              Tinker anywhere you want to and then come to our mobile app to
              complete your learning experience. Join our tribe of tinkerers,
              collaborate on projects and share your learnings and challenges
              with them. Explore daily fun challenges, fun facts and find out
              your learning gaps with our adaptive assessments!
            </p>

            <a
              href="https://play.google.com/store/search?q=tan90&c=apps"
              target="_blank"
              ><button class="btn-style3"><b>Download Now</b></button></a
            >
          </div>
          <div
            class="col-lg-6 col-md-6 align-items-center mt-3rem"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div
              id="carouselExampleControls"
              class="carousel slide mx-auto"
              data-bs-ride="carousel"
            >
              <div id="fstcarousel" class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="../../public/assets/img/tribe/Artboard 1.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Artboard 2.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Artboard 3.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Artboard 4.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Brain Teaser-1.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Home page-1.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Language switch screen.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="../../public/assets/img/tribe/Study Page-1.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Product Section Ends-->
    <!-- Team Section -->
    <Team />
    <!-- Contact section -->
    <Contact />
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from "./Contact.vue";
import Team from "./Team.vue";
export default {
  name: "Home",
  components: {
    Contact,
    Team,
  },
};
</script>
