<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex justify-content-between">
      <div class="logo">
        <!-- Uncomment below if you prefer to use an text logo -->
        <!-- <h1><a href="index.html">NewBiz</a></h1> -->
        <a href="/"
          ><img
            src="../../public/assets/img/logo/logo1.png"
            alt=""
            class="img-fluid"
        /></a>
      </div>

      <nav id="navbar" class="navbar">
        <!-- <router-link to="/discover">DISCOVER</router-link>
        <router-link to="/team">TEAM</router-link>
        <router-link to="/contact">CONTACT</router-link> -->

        <ul>
          <li>
            <a class="nav-link scrollto active" href="#about">ABOUT</a>
          </li>
          <li><a class="nav-link scrollto" href="#team">TEAM</a></li>
          <li>
            <a
              class="nav-link scrollto"
              href="https://www.linkedin.com/in/medha-krishnan-a3044457/?originalSubdomain=in"
              target="_blank"
              >CAREERS</a
            >
          </li>
          <li><a class="nav-link scrollto" href="#contact">CONTACT</a></li>

          <a href="https://tan-ninety.myshopify.com/" target="_blank">
            <button class="btn-style1">SHOP NOW</button></a
          >

          <!-- <button class="btn-style ml-15" @click="login">PLAY GAME</button> -->
          <button v-if="isSignIn" class="btn-style ml-15" @click="logout">
            LOGOUT
          </button>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- #header -->
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      isSignIn: null,
    };
  },

  // mounted() {
  //   // whenever question changes, this function will run
  //   // isSignIn(newQuestion) {
  //   //   this.isSignIn = newQuestion
  //   // }
  //   console.log(this.$store.getters.isSignIn);
  //   this.isSignIn = this.$store.getters.isSignIn;
  // },
  // computed: {
  //   // a computed getter
  //   isSignIn() {
  //     // `this` points to the component instance
  //     return this.$store.getters.isSignIn;
  //   }
  // },
  // watch: {
  //   // whenever question changes, this function will run
  //   isSignIn(newQuestion) {
  //     console.log(newQuestion, "newQuestion", old);
  //     this.isSignIn = newQuestion;
  //   },
  //   // this.isSignIn = this.$store.getters.isSignIn;
  // },
  methods: {
    async login() {
      const googleUser = await this.$gAuth.signIn();
      console.log(googleUser);
      this.isSignIn = await this.$gAuth.isAuthorized;
      this.$store.commit("ISSIGNIN", this.isSignIn);
      if (this.isSignIn == true) {
        this.$router.push("/play");
      } else {
        alert("Please login through your valid Google Account");
        this.$router.push("/");
      }
    },
    logout() {
      this.$store.commit("ISSIGNIN", false);
      this.isSignIn = this.$store.getters.isSignIn;
      console.log(this.isSignIn);
      this.$router.push("/");
    },
    // logout() {
    //   this.$store.commit("ISSIGNIN", false);
    //   this.isSignIn = false;
    //   this.$router.push("/");
    // },
  },
};
</script>
