<template>
  <!-- ======= Team Section ======= -->
  <section id="team" class="team team-pb">
    <div class="container">
      <div class="section-title">
        <p class="text-center">TEAM</p>
      </div>

      <div class="container">
        <div class="row">
          <!-- column  -->

          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-10.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">
                      Prerana Jayakumar
                    </h5>
                    <h6 class="subtitle mb-1">Product Designer</h6>
                    <div class="social">
                      <a
                        href="https://www.linkedin.com/in/prerana-jayakumar-4a8305193"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-12.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">
                      Ashish Navalakha
                    </h5>
                    <h6 class="subtitle mb-1">Product Lead</h6>
                    <div class="social">
                      <a
                        href="https://www.linkedin.com/in/ashishnavalakha/?originalSubdomain=in"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column  -->
          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-6.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">
                      Scindhia Siddharthan
                    </h5>
                    <h6 class="subtitle mb-1">Operations Lead</h6>
                    <div class="social">
                      <a
                        href="https://in.linkedin.com/in/scindhia-siddharthan-168823b5"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column  -->
          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-5.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">Sarath Babu M</h5>
                    <h6 class="subtitle mb-1">Product Lead</h6>
                    <div class="social">
                      <a
                        href="https://in.linkedin.com/in/sarathbabum"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column -->
        </div>
        <!-- Second row  -->
        <div class="row mt-5">
          <!-- column  -->
          <div class="offset-lg-1 col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-1.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">Santosh More</h5>
                    <h6 class="subtitle mb-1">Co Founder</h6>
                    <div class="social">
                      <a
                        href="https://in.linkedin.com/in/santosh-more-19716417"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column  -->
          <!-- column  -->
          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-2.jpg"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">Medha Krishnan</h5>
                    <h6 class="subtitle mb-1">Co Founder</h6>
                    <div class="social">
                      <a
                        href="https://in.linkedin.com/in/medha-krishnan-a3044457"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column  -->
          <!-- column  -->
          <div class="col-lg-3 text-center">
            <!-- Row -->
            <div class="row">
              <div class="col-md-12">
                <div class="bg-white rounded team-image-padding">
                  <img
                    src="assets/img/team/team-8.png"
                    alt="wrapkit"
                    class="img-fluid rounded-circle img-thumbnail"
                  />
                  <div class="">
                    <h5 class="mt-2 font-weight-medium mb-0">Jonah Filmore</h5>
                    <h6 class="subtitle mb-1">Media Lead</h6>
                    <div class="social">
                      <a
                        href="https://in.linkedin.com/in/jonah-filmore-92295b183?trk=people-guest_people_search-card"
                        target="_blank"
                        ><i class="icofont-linkedin icon-size"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- column  -->
        </div>
        <!-- Third row  -->
        <div class="row mt-5">
          <!-- column  -->
          <div class="col-lg-4 text-center">
            <!-- Row -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mx-auto text-center">
          <a
            href="https://www.linkedin.com/in/medha-krishnan-a3044457/?originalSubdomain=in"
            target="_blank"
            ><button class="btn-style3"><b>Join Us</b></button></a
          >
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->
</template>
<script>
export default {
  name: "Team",
};
</script>
