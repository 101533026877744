import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex);

const state = {
    isSignIn: false
};

const mutations = {
    ISSIGNIN(state, payload) {
        state.isSignIn = payload
    },
};

const getters = {
    isSignIn(state) {
        return state.isSignIn;
    },
}

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state,
    mutations,
    getters
});