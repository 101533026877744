import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";



Vue.config.productionTip = false

import GAuth from 'vue-google-oauth2'
const gauthOption = {
    clientId: "497573793884-9kf5ajp81j7v9fcpasuqvif3oippk908.apps.googleusercontent.com",
    scope: "profile email",
    prompt: "consent",
};
Vue.use(GAuth, gauthOption)
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    if (to.meta.requiresAuth) {
        const authUser = store.getters.isSignIn
        if (authUser) {
            next()
        } else {
            next({
                name: 'Home'
            })
        }
    }
    next()
})

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


// import GAuth from 'vue-google-oauth2'
// const gauthOption = {
//     clientid: '497573793884-9kf5ajp81j7v9fcpasuqvif3oippk908.apps.googleusercontent.com',
//     scope: 'profile email',
//     prompt: 'consent',
//     fetch_basic_profile: true
// }
// Vue.use(GAuth, gauthOption)



/* eslint-disable no-new */
// new Vue({
//     el: '#app',
//     router,
//     template: '<App/>',
//     components: { App }
// })