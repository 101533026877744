import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)




const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/play',
        name: 'Play',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Play.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/explore',
        name: 'Explore',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Explore.vue'),
    },
    {
        path: '/privacypolicy',
        name: 'Privacypolicy',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Privacypolicy.vue'),
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue'),
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    if (to.meta.requiresAuth) {
        const authUser = store.getters.isSignIn
        if (authUser) {
            next()
        } else {
            next({
                name: 'Home'
            })
        }
    }
    next()
})

export default router